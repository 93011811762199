import React, {useMemo, useState} from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {Badge, Callout, Icon} from "@tremor/react";
import {ClockIcon, InformationCircleIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import SnapshotCard from "../../../components/SnapshotCard";
import {formatNumber} from "../../../utils/numbers";
import Modal from "../../../ui/Modal";
import VideosTable from "../../../components/VideosTable";
import Card from "../../../ui/Card";
import Status from "../../../ui/Status";
import {Plan} from "../../../models/Plan";
import {SubscribeModal} from "../../subscription";
import {useModalStore} from "../../../store/modal";

type RecommendedVideosProps = {
    topic: Topic
}

function RecommendedVideos({topic}: RecommendedVideosProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    const isPlanTrial = useMemo(() => {
        if (!localStorage.getItem("plan")) return false;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        if (!plan) return false;
        return plan.is_trial && plan.channel_count == 25;
    }, [])

    const snapshotsList = useMemo(() => {
        if (!topic.recommended_snapshots || topic.recommended_snapshots.length === 0) {
            return <Status
                icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                headline={t("video_page.no_data")}
                text={t("topic_page.no_recommended_videos")}
            />;
        }

        return topic.recommended_snapshots.map((snap, i) => {
            return <SnapshotCard
                key={i}
                snap={snap}
                blocked={isPlanTrial && i !== 0}
                onClick={(isPlanTrial && i !== 0) ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : () => {setModalShown(true)}}
                bottomText={
                    !isPlanTrial || i === 0 ? t("video_page.stats.table.views") + " " + formatNumber(snap.views)
                        : <div className="flex items-center">{t("video_page.stats.table.views")} <Icon className="p-0 ml-1" icon={LockClosedIcon} color="gray" size="xs"/></div>
                }
            />;
        });
    }, [topic, isPlanTrial]);

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-center text-lg mb-8 font-bold">{t("topic_page.recommended_videos")}</div>
            <Card>
                <div className="flex justify-center items-start">
                    <Badge size="sm" color="green" className="mb-8"><div className="blue-links" dangerouslySetInnerHTML={{__html: t("topic_page.recommended_videos_bot")!}}></div></Badge>
                </div>
                <div className="flex gap-4">
                    {snapshotsList}
                </div>

                {topic.recommended_snapshots && topic.recommended_snapshots.length ?
                    <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {setModalShown(true)}}>
                        {t("topic_page.view_all_list")}
                    </div>
                    : <></>
                }

                <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                    <div className="text-center text-lg mb-8 font-bold">
                        {t("topic_page.recommended_videos")}
                    </div>
                    <Callout
                        title={t("topic_page.recommended_videos_callout")}
                        color="green"
                        icon={InformationCircleIcon}
                    >
                        {t("topic_page.recommended_videos_callout_text")}
                    </Callout>
                    <>
                        {topic.recommended_snapshots && topic.recommended_snapshots.length &&
                            <VideosTable
                                blocked={isPlanTrial}
                                onClick={isPlanTrial ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : undefined}
                                snapshots={topic.recommended_snapshots}
                                tableColumns={["preview", "title", "serial_number", "views", "vs", "erv", "score"]}
                            />
                        }
                    </>
                </Modal>
            </Card>
        </WideCard>
    );
}

export default RecommendedVideos;