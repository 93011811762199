import React from 'react';
import {AccountSettings, Referral, TelegramBot} from "../modules/settings";
import Breadcrumbs from "../components/Breadcrumbs";
import {useTranslation} from "react-i18next";
import {useLoaderData} from "react-router-dom";
import {SubscriptionResponse} from "../models/SubscriptionResponse";
import {SubscriptionInfo} from "../modules/subscription";

function SettingsPage() {
    const {t} = useTranslation();
    const response: SubscriptionResponse = useLoaderData() as SubscriptionResponse;

    return (
        <div className="pt-8">
            <Breadcrumbs pages={[
                {
                    title: t("breadcrumbs.settings"),
                    link: ""
                }
            ]} />
            <AccountSettings/>
            <Referral/>
            {!!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!).role === 0 &&
                <SubscriptionInfo response={response} />
            }
            <TelegramBot/>
        </div>
    );
}

export default SettingsPage;