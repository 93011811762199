import React, {useEffect, useMemo, useRef, useState} from 'react';
import {AxiosResponse} from "axios";
import {ChannelVideosRating} from "../models/ChannelVideosRating";
import {Channel} from "../models/Channel";
import Card from "../ui/Card";
import {Button, Icon, Tab, TabGroup, TabList} from "@tremor/react";
import {useTranslation} from "react-i18next";
import PeriodVideoRatingList from "./PeriodVideoRatingList";
import {Topic} from "../models/Topic";
import {XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../ui/Status";
import {Plan} from "../models/Plan";

type RatingFuncPeriod = (id: string, serial: number, shorts?: boolean) => Promise<AxiosResponse<ChannelVideosRating>>;

type PeriodVideoRatingProps = {
    channel?: Channel
    topic?: Topic
    requestRating: RatingFuncPeriod
    shorts: boolean
}

function PeriodVideoRating({requestRating, channel, topic, shorts}: PeriodVideoRatingProps) {
    const {t} = useTranslation();

    const calendarRef = useRef<HTMLDivElement>(null);
    const shouldFetch = useRef(false);
    shouldFetch.current = true;

    const [period, setPeriod] = useState(100);
    const [periodIndex, setPeriodIndex] = useState(shorts ? 2 : 5);
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState<ChannelVideosRating>();

    const [responseCode, setResponseCode] = useState<number>();

    const [calendarShown, setCalendarShown] = useState(false);
    const [calendarDaily, setCalendarDaily] = useState(shorts);
    const [calendarSelected, setCalendarSelected] = useState(1);

    const isPlanTrial = useMemo(() => {
        if (!localStorage.getItem("plan")) return false;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        if (!plan) return false;
        return plan.is_trial && plan.channel_count == 25;
    }, []);

    const toggleVariants = useMemo(() => {
        if (shorts) return ["24", "48", "100", "exact"];
        return ["3", "6", "12", "24", "48", "100", "exact"];
    }, [shorts]);

    useEffect(() => {
        if (shorts) {
            setCalendarDaily(true);
            handleValueChange(2);
            setPeriodIndex(2);
        } else {
            setCalendarDaily(false);
            handleValueChange(5);
            setPeriodIndex(5);
        }
    }, [shorts]);

    useEffect(() => {
        setLoading(true);

        if (shouldFetch.current) {
            fetchData().catch((e) => {
                setResponseCode(e.response.status);
                setLoading(false);
            });
        }

        return () => {
            shouldFetch.current = true;
        }
    }, [requestRating, period, channel, topic, shorts]);

    const fetchData = async () => {
        let res;
        if (channel) {
            res = await requestRating(channel!.id, period, shorts);
        } else if (topic) {
            res = await requestRating(topic!.id, period, shorts);
        }

        setTimeout(() => {
            setLoading(false);
        }, 500);

        if  (res) {
            setRating(res.data);
            setResponseCode(res.status);
        }
    }

    const togglePeriod = useMemo(() => {
        if (period === 3 || period === 6 || period === 12 || period === 24 || period === 48 || period === 100) {
            return period.toString();
        }

        return "exact";
    }, [period])

    function handleValueChange(index: number) {
        let value = toggleVariants[index];
        if (value === "exact") {
            setCalendarShown(true);
            return;
        }

        setPeriod(parseInt(value));
    }

    function handleClickOutside(event: MouseEvent) {
        if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setCalendarShown(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function getNumbers(daily: boolean) {
        let content = [];
        for (let i = 1; i <= (daily ? 30 : 48); i++) {
            content.push(
                <div
                    key={i}
                    className={"rounded-full flex items-center justify-center text-xs w-6 h-6 shrink-0 cursor-pointer " + (calendarSelected === i ? "bg-blue-500 text-white" : "bg-white")}
                    onClick={() => {setCalendarSelected(i)}}
                >
                    {i}
                </div>
            );
        }
        return content;
    }

    return (
        <>
            <Card className="overflow-visible">
                <TabGroup
                    color="zinc"
                    index={periodIndex}
                    defaultIndex={shorts ? 2 : 5}
                    onIndexChange={handleValueChange}
                    className="relative"
                >
                    <TabList variant="solid">
                        <>
                            {!shorts && <>
                                <Tab>{t("channel_page.video_rating.3")}</Tab>
                                <Tab>{t("channel_page.video_rating.6")}</Tab>
                                <Tab>{t("channel_page.video_rating.12")}</Tab>
                            </>}
                        </>
                        <Tab>{t("channel_page.video_rating.24")}</Tab>
                        <Tab>{t("channel_page.video_rating.48")}</Tab>
                        <Tab>{t("channel_page.video_rating.100")}</Tab>
                        <div className="relative z-50 overflow-visible">
                            <Tab onClick={() => {setCalendarShown(true)}}>{t("channel_page.video_rating.exact")}</Tab>
                            <div
                                ref={calendarRef}
                                className={"absolute bg-white p-4 w-96 top-full drop-shadow-md rounded-md right-0 translate-y-2 z-50 text-center border border-slate-200 " + (calendarShown ? "opacity-100 visible" : "opacity-0 invisible")}
                            >
                                <div className="mb-4 flex items-center gap-x-4">
                                    <div className="text-slate-500 text-sm">{t("channel_page.period")}</div>
                                    <TabGroup
                                        defaultIndex={shorts ? 1 : 0}
                                        onIndexChange={(index) => {
                                            setCalendarDaily(index === 1)
                                        }}
                                    >
                                        <TabList variant="solid">
                                            <>
                                                {!shorts && <Tab>{t("channel_page.video_rating.by_hours")}</Tab>}
                                            </>
                                            <Tab>{t("channel_page.video_rating.by_days")}</Tab>
                                        </TabList>
                                    </TabGroup>
                                </div>
                                <div className="flex gap-2 flex-wrap">
                                    {getNumbers(calendarDaily)}
                                </div>
                                <Button size="xs" className="mt-2" onClick={() => {
                                    if (calendarDaily) {
                                        setPeriod(calendarSelected * 24);
                                    } else {
                                        setPeriod(calendarSelected);
                                    }
                                    setCalendarShown(false);
                                }}>
                                    {t("channel_page.apply")}
                                </Button>
                            </div>
                        </div>
                    </TabList>
                </TabGroup>
            </Card>
            <>
                {(!rating && loading && !responseCode) && <Card className="mt-8 loadable loadable_active"> </Card>}
            </>
            <>
                {(!rating && !loading && responseCode && responseCode !== 200) && <Card className="mt-8">
                    <Status
                        icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                        headline={t("video_page.something_went_wrong")}
                        text={t("video_page.something_went_wrong_text")}
                    />
                </Card>}
            </>
            {rating && Object.keys(rating!).map(function(key, i) {
                return <PeriodVideoRatingList blocked={isPlanTrial && !!topic} key={i} loading={loading} rating={rating} field={key as ("views" | "vs" | "erv" | "score")} period={period}/>;
            })}
        </>
    );
}

export default PeriodVideoRating;