import React, {MouseEventHandler} from 'react';
import {Snapshot} from "../models/Snapshot";
import {Link} from "react-router-dom";
import {Icon} from "@tremor/react";
import {LockClosedIcon} from "@heroicons/react/20/solid";

type SnapshotCardProps = {
    snap: Snapshot
    bottomText?: JSX.Element | string
    blocked?: boolean
    onClick?: MouseEventHandler<HTMLElement>
};

function SnapshotCard({snap, bottomText, blocked, onClick}: SnapshotCardProps) {
    function defaultOnClick(e: any) {
        e.preventDefault();
    }

    if (blocked) {
        return <div className="block w-64 shrink-0 cursor-pointer" onClick={onClick ?? defaultOnClick}>
            <div className="h-36 w-full rounded-md bg-zinc-100 flex items-center justify-center">
                <Icon icon={LockClosedIcon} color="gray" size="lg"/>
            </div>
            <div className="text-sm mt-2 text-slate-700">{snap.title.substring(0, 5)}...</div>
            <div className="text-sm mt-1 text-slate-500">{bottomText}</div>
        </div>;
    }

    if (onClick) {
        return <div onClick={onClick} className="block w-64 shrink-0 cursor-pointer">
            <img src={import.meta.env.VITE_BACKEND_URL + snap.preview} alt="preview" className="block w-full rounded-md"/>
            <div className="text-sm mt-2 text-slate-700 break-words">{snap.title}</div>
            <div className="text-sm mt-1 text-slate-500">{bottomText}</div>
        </div>;
    }

    return (
        <Link to={"/video/" + snap.video_id} className="block w-64 shrink-0">
            <img src={import.meta.env.VITE_BACKEND_URL + snap.preview} alt="preview" className="block w-full rounded-md"/>
            <div className="text-sm mt-2 text-slate-700 break-words">{snap.title}</div>
            <div className="text-sm mt-1 text-slate-500">{bottomText}</div>
        </Link>
    );
}

export default SnapshotCard;