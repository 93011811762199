import React, {useMemo, useState} from 'react';
import {Button} from "@tremor/react";
import {PlusIcon} from "@heroicons/react/20/solid";
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import Modal from "../../../ui/Modal";
import {useTranslation} from "react-i18next";
import CreateTopic from "./CreateTopic";
import EditTopic from "./EditTopic";
import {Topic} from "../../../models/Topic";
import {useModalStore} from "../../../store/modal";

function AddTopic() {
    const {t} = useTranslation();

    const {setSubscribeModalOpen} = useModalStore();

    const [topic, setTopic] = useState<Topic>();
    const [modalShown, setModalShown] = useState(false);
    const [mode, setMode] = useState<"create" | "edit">("create");

    const modalHeading = useMemo(() => {
        if (mode === "create") return t("main_page.add_topic").toString();
        return t("main_page.add_channels").toString();
    }, [mode]);

    const hasSubscription = useMemo(() => {
        if (Boolean(!!localStorage.getItem("subscription") && JSON.parse(localStorage.getItem("subscription")!))) return true;
        if (!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role) return true;
        return false;
    }, []);

    function handleModalClose() {
        setModalShown(false);
        setMode("create");
        setTopic(undefined);
    }

    return (
        <>
                <Button icon={PlusIcon} onClick={() => {
                    if (hasSubscription) {
                        setModalShown(true);
                    } else {
                        setSubscribeModalOpen(true, {limit: false, trial: false});
                    }
                }}>{t("main_page.add_topic")}</Button>
                <Modal shown={modalShown} close={handleModalClose} widthClass="sm:max-w-4xl">
                    <div className="text-center text-lg font-bold mb-8">{modalHeading}</div>
                    {
                        mode === "create"
                            ? <CreateTopic topic={topic} setTopic={setTopic} setMode={setMode}/>
                            : <EditTopic topic={topic!} />
                    }
                </Modal>
        </>
    );
}

export default AddTopic;