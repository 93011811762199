import React, {useMemo, useState} from 'react';
import {Channel} from "../models/Channel";
import {useTranslation} from "react-i18next";
import {
    Icon, Tab,
    TabGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TabList
} from "@tremor/react";
import {ArchiveBoxXMarkIcon, BarsArrowDownIcon, BarsArrowUpIcon, NoSymbolIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {formatNumber} from "../utils/numbers";
import {secondsToTimeString} from "../utils/date";
import {useShortsStore} from "../store/shorts";

type SortKey = "avg_views" | "sum_views" | "median_vs" | "median_erv" | "median_score" | "current_videos" | "subscriptions" | "median_length" | "avg_views_shorts" | "sum_views_shorts" | "median_vs_shorts" | "median_erv_shorts" | "median_score_shorts" | "current_videos_shorts" | "median_length_shorts"

type ChannelsTableProps = {
    channels: Channel[]
    highlightId?: string
    initialSortingKey: SortKey | null
    shorts: boolean
}

function ChannelsTable({channels, initialSortingKey, highlightId}: ChannelsTableProps) {
    const {shorts, setShorts} = useShortsStore();

    const tableColumns = useMemo(() => {
        if (shorts) {
            return [
                "sum_views_shorts",
                "median_vs_shorts",
                "median_erv_shorts",
                "current_videos_shorts",
                "avg_views_shorts",
                "median_length_shorts",
            ];
        }

        return [
            "sum_views",
            "median_vs",
            "median_erv",
            "current_videos",
            "avg_views",
            "median_length",
        ];
    }, [shorts]);

    const {t} = useTranslation();

    const [sortKey, setSortKey] = useState(initialSortingKey);
    const [sortDesc, setSortDesc] = useState(true);

    const sortedChannels = useMemo(() => {
        if (!sortKey) return channels;
        if (!channels) return [];

        const result = [...channels].sort((a, b) => {
            return (a[sortKey!] as number) - (b[sortKey!] as number);
        });

        if (sortDesc) return result.reverse();

        return result;
    }, [channels, sortKey, sortDesc])

    function handleSortChange(key: SortKey) {
        if (sortKey === key) {
            setSortDesc(!sortDesc);
            return;
        }

        setSortDesc(true);
        setSortKey(key);

        return;
    }

    function handleIndexChange(value: number) {
        if (value == 0) {
            setShorts(false);
        } else {
            setShorts(true);
        }
    }

    return (
        <div className="relative">
            <div className="absolute flex items-center p-4 py-2 z-50">
                <TabGroup
                    color="zinc"
                    index={shorts ? 1 : 0}
                    defaultIndex={shorts ? 1 : 0}
                    onIndexChange={handleIndexChange}
                    className="relative"
                >
                    <TabList variant="solid">
                        <Tab >
                            <div className="flex items-center text-xs">
                                {t('topic_page.long_videos_short')}
                            </div>
                        </Tab>
                        <Tab>
                            <div className="flex items-center text-xs">
                                {t('topic_page.shorts')}
                            </div>
                        </Tab>
                    </TabList>
                </TabGroup>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                        {tableColumns.map((el, i) => {
                            return <TableHeaderCell
                                className="cursor-pointer text-slate-700"
                                onClick={handleSortChange.bind(null, el as SortKey)}
                                key={i}>
                                <div className="flex items-bottom">
                                    {
                                        t("channel_page.rating." + el.replace("_shorts", "")) === ("channel_page.rating." + el.replace("_shorts", ""))
                                            ? ""
                                            : el.replace("_shorts", "") == "avg_views" ? t("channel_page.rating." + el.replace("_shorts", "") + "_short") : t("channel_page.rating." + el.replace("_shorts", ""))
                                    }
                                    <Icon
                                        style={{
                                            opacity: sortKey === el ? 1 : 0,
                                            visibility: sortKey === el ? "visible" : "hidden",
                                        }}
                                        size="xs"
                                        color="slate"
                                        icon={ sortDesc ? BarsArrowDownIcon : BarsArrowUpIcon }
                                    />
                                </div>
                            </TableHeaderCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedChannels.map((item) => (
                        <TableRow key={item.id} className={initialSortingKey && item.id === highlightId ? "bg-lime-200" : ""}>
                            <TableCell>
                                <Link target="_blank" to={"/channel/" + item.id}>
                                    {(item.deleted || item.blocked)
                                        ? <div className="w-24 h-24 rounded-full bg-red-100 flex justify-center items-center">
                                            <Icon icon={item.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="sm" />
                                        </div>
                                        : <img src={item.avatar} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src="/person.svg";
                                        }} alt="avatar" className="rounded-full w-24"/>
                                    }
                                </Link>
                            </TableCell>
                            <TableCell className="text-slate-700 text-sm">
                                <Link target="_blank" to={"/channel/" + item.id}>
                                    <div className="whitespace-break-spaces text-blue-500">{item.title}</div>
                                </Link>
                            </TableCell>
                            <>
                                {shorts ? <>
                                        <TableCell className="text-slate-700">{formatNumber(item.sum_views_shorts)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_vs_shorts, true)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_erv_shorts, true)}</TableCell>
                                        {/*<TableCell className="text-slate-700">{formatNumber(Math.ceil(item.median_score_shorts))}</TableCell>*/}
                                        <TableCell className="text-slate-700">{formatNumber(item.current_videos_shorts)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.avg_views_shorts)}</TableCell>
                                        <TableCell className="text-slate-700">{secondsToTimeString(item.median_length_shorts)}</TableCell>
                                    </>
                                    : <>
                                        <TableCell className="text-slate-700">{formatNumber(item.sum_views)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_vs, true)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_erv, true)}</TableCell>
                                        {/*<TableCell className="text-slate-700">{formatNumber(Math.ceil(item.median_score))}</TableCell>*/}
                                        <TableCell className="text-slate-700">{formatNumber(item.current_videos)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.avg_views)}</TableCell>
                                        <TableCell className="text-slate-700">{secondsToTimeString(item.median_length)}</TableCell>
                                    </>
                                }
                            </>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default ChannelsTable;