import React, {MouseEventHandler, useMemo, useState} from 'react';
import {Snapshot} from "../models/Snapshot";
import {
    Button,
    Icon,
    Tab, TabGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TabList
} from "@tremor/react";
import {
    BarsArrowDownIcon,
    BarsArrowUpIcon, BookmarkSlashIcon,
    LockClosedIcon, TrashIcon
} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {formatNumber} from "../utils/numbers";
import {useTranslation} from "react-i18next";
import {BookmarkIcon} from "@heroicons/react/24/outline";
import {addToFavourites, removeFromFavourites} from "../http/favourite";

import * as Sentry from "@sentry/react";
import {useShortsStore} from "../store/shorts";

type Key = "views" | "vs" | "erv" | "score" | "serial_number" | "created_at" | "preview" | "title"
type SortKey = "views" | "vs" | "erv" | "score" | "serial_number" | "created_at"

type VideosTableProps = {
    snapshots: Snapshot[]
    initialSortingKey?: SortKey
    tableColumns: Key[]
    highlightId?: string
    periodUnderTitle?: boolean
    hoursInPeriod?: boolean
    blocked?: boolean
    showViews?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
    favouritesPage?: boolean
    favouritesRemoveCallback?: (videoId: string) => void
}

function VideosTable({blocked, showViews, onClick, snapshots, initialSortingKey, tableColumns, highlightId, periodUnderTitle, hoursInPeriod, favouritesPage, favouritesRemoveCallback}: VideosTableProps) {
    const {t} = useTranslation();

    const [sortKey, setSortKey] = useState(initialSortingKey);
    const [sortDesc, setSortDesc] = useState(true);

    const sortedSnapshots = useMemo(() => {
        if (!sortKey) return snapshots;
        if (!snapshots) return [];

        const result = [...snapshots].sort((a, b) => {
            return (a[sortKey!] as number) - (b[sortKey!] as number);
        });

        if (sortDesc) return result.reverse();

        return result;
    }, [snapshots, sortKey, sortDesc]);

    const {shorts, setShorts} = useShortsStore();

    function handleSortChange(key: SortKey) {
        if (sortKey === key) {
            setSortDesc(!sortDesc);
            return;
        }

        setSortDesc(true);
        setSortKey(key);

        return;
    }

    function handleRemoveFromFavorites(videoId: string) {
        const fromFavourites = async () => {
            await removeFromFavourites(videoId);
        };

        if (!!favouritesRemoveCallback) {
            favouritesRemoveCallback(videoId);
        }

        fromFavourites().catch((e) => {
            Sentry.captureException(e)
        });
    }

    function defaultOnClick() {}

    function handleIndexChange(value: number) {
        if (value == 0) {
            setShorts(false);
        } else {
            setShorts(true);
        }
    }

    if (blocked) {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((el, i) => {
                            return <TableHeaderCell
                                className="cursor-pointer text-slate-700"
                                key={i}>
                                <div className="flex items-bottom">
                                    {t("video_page.stats.table." + el) === ("video_page.stats.table." + el) ? "" : t("video_page.stats.table." + el)}
                                    <Icon
                                        style={{
                                            opacity: sortKey === el ? 1 : 0,
                                            visibility: sortKey === el ? "visible" : "hidden",
                                        }}
                                        size="xs"
                                        color="slate"
                                        icon={ sortDesc ? BarsArrowDownIcon : BarsArrowUpIcon }
                                    />
                                </div>
                            </TableHeaderCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className="group">
                        {tableColumns.includes("preview")
                            ? <TableCell>
                                <Link target="_blank" to={"/video/" + sortedSnapshots[0].video_id}>
                                    <img src={import.meta.env.VITE_BACKEND_URL + sortedSnapshots[0].preview} className="rounded-md aspect-video w-36 min-w-[100px]"/>
                                </Link>
                            </TableCell>
                            : ""
                        }
                        {tableColumns.includes("title")
                            ? <TableCell className="text-slate-700 align-top text-sm">
                                <Link target="_blank" to={"/video/" + sortedSnapshots[0].video_id}>
                                    <div className=" whitespace-break-spaces">{sortedSnapshots[0].title}</div>
                                </Link>
                                {periodUnderTitle && <div className="text-xs text-slate-500">
                                    {t("channel_page.period")}: {sortedSnapshots[0].serial_number % 24 === 0 && sortedSnapshots[0].serial_number != 0
                                    ? <>{sortedSnapshots[0].serial_number / 24} {t("video_page.stats.day_observation")}</>
                                    : (sortedSnapshots[0].serial_number != 0
                                        ? <>{sortedSnapshots[0].serial_number} {t("video_page.stats.hour_observation")}</>
                                        : <>{t("video_page.stats.start")}</>)
                                }
                                </div>}
                            </TableCell>
                            : ""
                        }
                        {tableColumns.includes("serial_number")
                            ? <TableCell className="text-slate-700">
                                {
                                    sortedSnapshots[0].serial_number != 0 ? (hoursInPeriod
                                            ? <>{sortedSnapshots[0].serial_number} {t("video_page.stats.hour_observation")}</>
                                            : (sortedSnapshots[0].serial_number % 24 === 0 && sortedSnapshots[0].serial_number != 0
                                                    ? <>{sortedSnapshots[0].serial_number / 24} {t("video_page.stats.day_observation")}</>
                                                    : <>{sortedSnapshots[0].serial_number} {t("video_page.stats.hour_observation")}</>
                                            )
                                    ) : <>{t("video_page.stats.start")}</>
                                }
                            </TableCell>
                            : ""
                        }
                        {tableColumns.includes("views")
                            ? <TableCell className="text-slate-700">{formatNumber(sortedSnapshots[0].views)}</TableCell>
                            : ""
                        }
                        {tableColumns.includes("vs")
                            ? <TableCell className="text-slate-700">{formatNumber(sortedSnapshots[0].vs, true)}</TableCell>
                            : ""
                        }
                        {tableColumns.includes("erv")
                            ? <TableCell className="text-slate-700">{formatNumber(sortedSnapshots[0].erv, true)}</TableCell>
                            : ""
                        }
                        {tableColumns.includes("score")
                            ? <TableCell className="text-slate-700">{formatNumber(sortedSnapshots[0].score)}</TableCell>
                            : ""
                        }
                    </TableRow>
                    {sortedSnapshots.filter((_, i) => i !== 0).map((item) => (
                        <TableRow key={item.id} className={initialSortingKey && item.id === highlightId ? "bg-lime-200" : ""}>
                            {tableColumns.includes("preview")
                                ? <TableCell>
                                    {showViews
                                        ? <Link target="_blank" to={"/video/" + item.video_id}>
                                            <img src={import.meta.env.VITE_BACKEND_URL + item.preview} className="rounded-md aspect-video w-36 min-w-[100px]"/>
                                        </Link>
                                        : <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                            <div className="h-20 w-36 aspect-video rounded-md bg-zinc-100 flex items-center justify-center">
                                                <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                            </div>
                                        </div>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("title")
                                ? <TableCell className="text-slate-700  text-sm">
                                    {showViews
                                        ? <Link target="_blank" to={"/video/" + item.video_id}>
                                            <div className="text-blue-500 whitespace-break-spaces">{item.title}</div>
                                        </Link>
                                        : <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                            <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                        </div>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("serial_number")
                                ? <TableCell className="text-slate-700">
                                    {
                                        item.serial_number != 0 ? (hoursInPeriod
                                                ? <>{item.serial_number} {t("video_page.stats.hour_observation")}</>
                                                : (item.serial_number % 24 === 0 && item.serial_number != 0
                                                        ? <>{item.serial_number / 24} {t("video_page.stats.day_observation")}</>
                                                        : <>{item.serial_number} {t("video_page.stats.hour_observation")}</>
                                                )
                                        ) : <>{t("video_page.stats.start")}</>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("views")
                                ? <TableCell className="text-slate-700">
                                    {showViews
                                        ? <>{formatNumber(item.views)}</>
                                        : <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                            <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                        </div>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("vs")
                                ? <TableCell className="text-slate-700">
                                    <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                        <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                    </div>
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("erv")
                                ? <TableCell className="text-slate-700">
                                    <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                        <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                    </div>
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("score")
                                ? <TableCell className="text-slate-700">
                                    <div className="cursor-pointer" onClick={onClick ?? defaultOnClick}>
                                        <Icon icon={LockClosedIcon} color="gray" size="xs"/>
                                    </div>
                                </TableCell>
                                : ""
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <div className="relative">
            <div className="absolute flex items-center p-4 py-2 z-50">
                <TabGroup
                    color="zinc"
                    index={shorts ? 1 : 0}
                    defaultIndex={shorts ? 1 : 0}
                    onIndexChange={handleIndexChange}
                    className="relative"
                >
                    <TabList variant="solid">
                        <Tab>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88" style={{width: 16, height: 16, marginRight: 4}}>
                                    <svg viewBox="0 0 28.57 20" preserveAspectRatio="xMidYMid meet">
                                        <g>
                                            <path d="M27.9727 3.12324C27.6435 1.89323 26.6768 0.926623 25.4468 0.597366C23.2197 2.24288e-07 14.285 0 14.285 0C14.285 0 5.35042 2.24288e-07 3.12323 0.597366C1.89323 0.926623 0.926623 1.89323 0.597366 3.12324C2.24288e-07 5.35042 0 10 0 10C0 10 2.24288e-07 14.6496 0.597366 16.8768C0.926623 18.1068 1.89323 19.0734 3.12323 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6768 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9727 3.12324Z" fill="#FF0000"/>
                                            <path d="M11.4253 14.2854L18.8477 10.0004L11.4253 5.71533V14.2854Z" fill="white"/>
                                        </g>
                                    </svg>
                                </svg>
                                {t('topic_page.long_videos')}
                            </div>
                        </Tab>
                        <Tab>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88" style={{width: 12, height: 12, marginRight: 4}}>
                                    <path d="M63.49 2.71c11.59-6.04 25.94-1.64 32.04 9.83 6.1 11.47 1.65 25.66-9.94 31.7l-9.53 5.01c8.21.3 16.04 4.81 20.14 12.52 6.1 11.47 1.66 25.66-9.94 31.7l-50.82 26.7c-11.59 6.04-25.94 1.64-32.04-9.83-6.1-11.47-1.65-25.66 9.94-31.7l9.53-5.01c-8.21-.3-16.04-4.81-20.14-12.52-6.1-11.47-1.65-25.66 9.94-31.7l50.82-26.7zM36.06 42.53l30.76 18.99-30.76 18.9V42.53z" fill="#f40407"/>
                                    <path d="M36.06,42.53 V 80.42 L 66.82,61.52Z" fill="#fff"/>
                                </svg>
                                {t('topic_page.shorts')}
                            </div>
                        </Tab>
                    </TabList>
                </TabGroup>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((el, i) => {
                            return <TableHeaderCell
                                className="cursor-pointer text-slate-700"
                                onClick={handleSortChange.bind(null, el as SortKey)}
                                key={i}>
                                <div className="flex items-bottom">
                                    {t("video_page.stats.table." + el) === ("video_page.stats.table." + el) ? "" : t("video_page.stats.table." + el)}
                                    <Icon
                                        style={{
                                            opacity: sortKey === el ? 1 : 0,
                                            visibility: sortKey === el ? "visible" : "hidden",
                                        }}
                                        size="xs"
                                        color="slate"
                                        icon={ sortDesc ? BarsArrowDownIcon : BarsArrowUpIcon }
                                    />
                                </div>
                            </TableHeaderCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedSnapshots.map((item) => (
                        <TableRow key={item.id} className={"group " + (initialSortingKey && item.id === highlightId ? "bg-lime-200" : "")}>
                            {tableColumns.includes("preview")
                                ? <TableCell>
                                    {favouritesPage
                                        ? <div className="rounded-md aspect-video w-36 min-w-[100px] overflow-hidden relative">
                                            <div className="absolute w-full h-full bg-gray-950/50 opacity-0 group-hover:opacity-100 transition-all cursor-pointer flex items-center justify-center" onClick={() => {handleRemoveFromFavorites(item.video_id)}}>
                                                <Icon icon={TrashIcon} className="text-white" size="xl"></Icon>
                                            </div>
                                            <img src={import.meta.env.VITE_BACKEND_URL + item.preview} className="w-full h-full"/>
                                        </div>
                                        : <Link target="_blank" to={"/video/" + item.video_id}>
                                            <img src={import.meta.env.VITE_BACKEND_URL + item.preview} className="rounded-md aspect-video w-36 min-w-[100px]"/>
                                        </Link>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("title")
                                ? <TableCell className="text-slate-700 align-top text-sm">
                                    <Link target="_blank" to={"/video/" + item.video_id}>
                                        <div className=" whitespace-break-spaces text-blue-500">{item.title}</div>
                                    </Link>
                                    {periodUnderTitle && <div className="text-xs text-slate-500">
                                        {t("channel_page.period")}: {item.serial_number % 24 === 0 && item.serial_number != 0
                                        ? <>{item.serial_number / 24} {t("video_page.stats.day_observation")}</>
                                        : (item.serial_number != 0
                                            ? <>{item.serial_number} {t("video_page.stats.hour_observation")}</>
                                            : <>{t("video_page.stats.start")}</>)
                                    }
                                    </div>}
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("serial_number")
                                ? <TableCell className="text-slate-700">
                                    {
                                        item.serial_number != 0 ? (hoursInPeriod
                                                ? <>{item.serial_number} {t("video_page.stats.hour_observation")}</>
                                                : (item.serial_number % 24 === 0 && item.serial_number != 0
                                                        ? <>{item.serial_number / 24} {t("video_page.stats.day_observation")}</>
                                                        : <>{item.serial_number} {t("video_page.stats.hour_observation")}</>
                                                )
                                        ) : <>{t("video_page.stats.start")}</>
                                    }
                                </TableCell>
                                : ""
                            }
                            {tableColumns.includes("views")
                                ? <TableCell className="text-slate-700">{formatNumber(item.views)}</TableCell>
                                : ""
                            }
                            {tableColumns.includes("vs")
                                ? <TableCell className="text-slate-700">{formatNumber(item.vs, true)}</TableCell>
                                : ""
                            }
                            {tableColumns.includes("erv")
                                ? <TableCell className="text-slate-700">{formatNumber(item.erv, true)}</TableCell>
                                : ""
                            }
                            {tableColumns.includes("score")
                                ? <TableCell className="text-slate-700">{formatNumber(item.score)}</TableCell>
                                : ""
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default VideosTable;