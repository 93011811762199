import React, {useState} from 'react';
import {formatNumber} from "../utils/numbers";
import {useTranslation} from "react-i18next";

type CategoryBarProps = {
    currentValue: number
    sortedValues: number[]
    markerPosition: number
    stripeWidths: number[]
    stripeValues: number[]
    tooltipText: string
    markerColor: MarkerColor
    medianValue: number
}

export type MarkerColor = "emerald" | "yellow" | "rose";

function CategoryBar({sortedValues, markerPosition, stripeWidths, currentValue, markerColor, stripeValues, tooltipText, medianValue}: CategoryBarProps) {
    const {t} = useTranslation();

    const [tooltipShown, setTooltipShown] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState(0);
    const [tooltipCount, setTooltipCount] = useState(0);

    function handleMouseOver(event: any) {
        const parentWidth = event.currentTarget.parentNode.offsetWidth;
        let stripeWidth;

        if (event.target.classList.contains("bg-rose-500")) {
            stripeWidth = 0;
            setTooltipCount(stripeValues[0]);
        } else if (event.target.classList.contains("bg-yellow-500")) {
            stripeWidth = (stripeWidths[0] / 100) * parentWidth;
            setTooltipCount(stripeValues[1]);
        } else if (event.target.classList.contains("bg-emerald-500")) {
            stripeWidth = ((stripeWidths[0] / 100) * parentWidth) + ((stripeWidths[1] / 100) * parentWidth);
            setTooltipCount(stripeValues[2]);
        }

        setTooltipShown(true);
        setTooltipPosition(stripeWidth + event.clientX - event.target.getBoundingClientRect().left);
    }

    return (
        <div className="tremor-CategoryBar-root">
            <div className="tremor-CategoryBar-barWrapper relative w-full flex items-center h-2">
                <div className="flex-1 flex items-center h-full overflow-hidden rounded-md cursor-pointer" onMouseOver={handleMouseOver} onMouseMove={handleMouseOver} onMouseLeave={() => {setTooltipShown(false)}}>
                    <div className="tremor-CategoryBar-categoryBar h-full bg-rose-500" style={{width: stripeWidths[0].toFixed(2) + "%"}}/>
                    <div className="tremor-CategoryBar-categoryBar h-full bg-yellow-500" style={{width: stripeWidths[1].toFixed(2) + "%"}}/>
                    <div className="tremor-CategoryBar-categoryBar h-full bg-emerald-500" style={{width: stripeWidths[2].toFixed(2) + "%"}}/>
                </div>
                <div className="tremor-CategoryBar-markerWrapper absolute right-1/2 -translate-x-1/2 w-5" style={{left: markerPosition.toFixed(2) + "%", transition: "all 2s ease 0s"}}>
                    <div className={"text-gray-500 absolute right-1/2 -translate-y-6 text-sm " + (markerPosition < 98 ? (markerPosition < 2 ? "translate-x-full" : "translate-x-1/2") : "")}>{formatNumber(currentValue)}</div>
                    <div className={"tremor-CategoryBar-marker ring-2 mx-auto ring-white h-4 w-1 rounded-lg " + "bg-" + markerColor + "-500"}/>
                </div>
            </div>
            <div className="tremor-CategoryBar-labels relative flex w-full text-gray-500 mt-2 h-5 text-sm">
                <div
                    className={"absolute text-center transition-all px-4 w-24 py-1 text-sm rounded-md bg-slate-100 -translate-y-full -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                    style={{left: tooltipPosition.toString() + "px", bottom: "100%"}}
                >
                    {t(tooltipText, {count: tooltipCount})}
                </div>
                <div className="absolute bottom-0 flex items-center left-0">{formatNumber(sortedValues[0])}</div>
                <div className="absolute bottom-0 flex items-center left-1/2 -translate-x-1/2">{formatNumber(medianValue)}</div>
                <div className="absolute bottom-0 flex items-center right-0">{formatNumber(sortedValues[sortedValues.length - 1])}</div>
            </div>
        </div>
    );
}

export default CategoryBar;