import React, {useEffect, useMemo, useState} from 'react';
import {SubscriptionResponse} from "../../../models/SubscriptionResponse";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import Card from "../../../ui/Card";
import Status from "../../../ui/Status";
import {Button, Icon} from "@tremor/react";
import {CreditCardIcon} from "@heroicons/react/20/solid";
import SubscribeModal from "./SubscribeModal";
import {stringToDateString} from "../../../utils/date";
import {useSearchParams} from "react-router-dom";

type SubscriptionInfoProps = {
    response: SubscriptionResponse
}

function SubscriptionInfo({response}: SubscriptionInfoProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!!searchParams.get("modal")) {
            setModalShown(true);
        }
    }, [])

    if (!response.subscription || !response.plan) {
        return <WideCard>
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.subscription")}</div>
            <Card className="text-center">
                <Status
                    icon={<Icon icon={CreditCardIcon} size="xl" color="slate" />}
                    headline={t("settings_page.no_subscription")}
                    text={t("settings_page.no_subscription_text")}
                />
                <Button className="mt-4" onClick={() => {setModalShown(true);}}>
                    {t("settings_page.subscribe")}
                </Button>
            </Card>
            <SubscribeModal shown={modalShown} close={() => {setModalShown(false)}} />
        </WideCard>;
    }

    return (
        <WideCard>
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.subscription")}</div>
            <Card>
                <div className="grid grid-cols-2">
                    <div>
                        <div className="font-bold mb-2">
                            {t("settings_page.subscription_channels", {count: response.plan.channel_count})}
                        </div>
                        <div className="text-sm text-slate-500 mb-2">
                            {t("settings_page.subscription_expires")} <span className="font-bold">{stringToDateString(response.subscription.expires_at)}</span>
                        </div>
                        <div className="text-sm text-slate-500 mb-2">
                            {t("settings_page.subscription_channels_used", {count: response.channels_used})}
                        </div>
                        <div className="text-sm text-slate-500">
                            {t("settings_page.subscription_channels_limit", {count: response.subscription.channel_count, all: response.plan.channel_count * 3})}
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <Button onClick={() => {setModalShown(true);}}>{t("settings_page.renew_subscription")}</Button>
                    </div>
                </div>
            </Card>
            <SubscribeModal shown={modalShown} close={() => {setModalShown(false)}} />
        </WideCard>
    );
}

export default SubscriptionInfo;