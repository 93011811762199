import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import ChannelCard from "../../../components/ChannelCard";
import {Link} from "react-router-dom";
import {Badge, Button, Icon} from "@tremor/react";
import {CircleStackIcon, FolderOpenIcon, PencilSquareIcon, PlusIcon, TrashIcon} from "@heroicons/react/20/solid";
import EditTopic from "./EditTopic";
import Modal from "../../../ui/Modal";
import DeleteTopic from "./DeleteTopic";
import Status from "../../../ui/Status";
import {useTopicsStore} from "../store";
import {userTopics} from "../http";
import * as Sentry from "@sentry/react";
import {useModalStore} from "../../../store/modal";
import AddTopic from "./AddTopic";
function TopicsList() {
    const {t} = useTranslation();

    const {topics, loadingTopicIds, setTopics, removeTopicFromLoading} = useTopicsStore();

    const {setSubscribeModalOpen} = useModalStore();

    const [topic, setTopic] = useState<Topic>();
    const [modalShown, setModalShown] = useState(false);
    const [modalMode, setModalMode] = useState<"edit"|"delete">("edit");

    const loadingTopicsRef = useRef(useTopicsStore.getState().loadingTopicIds);
    useEffect(() => useTopicsStore.subscribe(
        (state) => (loadingTopicsRef.current = state.loadingTopicIds)
    ), []);

    const hasSubscription = useMemo(() => {
        if (Boolean(!!localStorage.getItem("subscription") && JSON.parse(localStorage.getItem("subscription")!))) return true;
        if (!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role) return true;
        return false;
    }, []);

    const fetchTopics = () => {
        setTimeout(async () => {
            let updatedTopics;
            try {
                updatedTopics = await userTopics();
                setTopics(updatedTopics.data);
            } catch (e) {
                Sentry.captureException(e);
                window.location.reload();
            }

            if (!!updatedTopics) {
                for (let t of updatedTopics.data) {
                    if (!!t.channels && t.channels.length !== 0 && !t.channels.some((c) => !c.title)) {
                        removeTopicFromLoading(t.id);
                    }
                }
            }

            if (loadingTopicsRef.current.length !== 0) {
                fetchTopics();
            }
        }, 60000)
    }

    useEffect(() => {
        if (loadingTopicIds.length !== 0) {
            fetchTopics();
        }
    }, [loadingTopicIds])

    function handleModalClose() {
        setModalShown(false);
        setTopic(undefined);
    }

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-center text-lg font-bold">{t("main_page.topics")}</div>
            <>
                {(!topics || topics.length === 0) && <Card className="mt-8">
                    <Status
                        icon={<Icon icon={FolderOpenIcon} color="slate" size="xl"></Icon>}
                        text={t("main_page.no_topics_text")}
                        headline={t("main_page.no_topics")}/>
                    <div className="text-center mt-4"><AddTopic/></div>
                </Card>}
                {topics && topics.map((el, i) => {
                    return <Card className={"mt-8 text-center"} key={i}>
                        <div className="font-bold flex items-center justify-between">
                            {(el.channels && el.channels.length != 0 && hasSubscription)
                              ? <Link to={"/topic/" + el.id}>
                                    {el.title}
                              </Link>
                              : <>{el.title}</>
                            }
                            <div className="">
                                <Icon
                                    icon={PencilSquareIcon}
                                    size="sm"
                                    color="slate"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (hasSubscription) {
                                            setTopic(el);
                                            setModalMode("edit");
                                            setModalShown(true);
                                        } else {
                                            setSubscribeModalOpen(true, {limit: false, trial: false});
                                        }
                                    }}
                                />
                                <Icon
                                    icon={TrashIcon}
                                    size="sm"
                                    color="slate"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (hasSubscription) {
                                            setTopic(el);
                                            setModalMode("delete");
                                            setModalShown(true);
                                        } else {
                                            setSubscribeModalOpen(true, {limit: false, trial: false});
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <>
                            {(!!el.videos_count && !!el.channels_count) &&
                                <div className="text-left text-md text-slate-500">{t("main_page.observing", {videos_count: el.videos_count, channels_count: el.channels_count})}</div>
                            }
                        </>
                        <div className="flex gap-8 mt-8">
                            {(el.channels && el.channels.length != 0) && el.channels!.slice(0, 5).map((channel, i) => {
                                return <ChannelCard
                                    key={i}
                                    channel={channel}
                                    to={"/topic/" + el.id}
                                    onClick={!hasSubscription ? (e) => {
                                       e.preventDefault();
                                       setSubscribeModalOpen(true, {limit: false, trial: false});
                                    } : undefined}
                                    bottomText={channel.subscriptions + " " + t("channel_page.subscribers")}
                                />;
                            })}
                            {(!el.channels || el.channels.length == 0) && <Status
                                icon={<Icon icon={FolderOpenIcon} color="slate" size="xl"></Icon>}
                                text={t("main_page.no_channels_text")}
                                headline={t("main_page.no_channels")}/>}
                        </div>
                        {(el.channels && el.channels.length != 0)
                            ? (hasSubscription
                            ? <Link to={"/topic/" + el.id} className="block text-blue-500 text-sm text-center mt-8 cursor-pointer">
                                {t("main_page.go_to_topic")}
                            </Link>
                            : <div onClick={() => {setSubscribeModalOpen(true, {limit: false, trial: false})}} className="block text-blue-500 text-sm text-center mt-8 cursor-pointer">{t("main_page.go_to_topic")}</div>)
                            : <Button className="mt-8" icon={PlusIcon} onClick={() => {
                                if (hasSubscription) {
                                    setTopic(el);
                                    setModalMode("edit");
                                    setModalShown(true);
                                } else {
                                    setSubscribeModalOpen(true, {limit: false, trial: false});
                                }
                            }}>{t("main_page.add_channels")}</Button>
                        }
                        <div className="text-center">{loadingTopicIds.includes(el.id) && <Badge icon={CircleStackIcon} size="xs" color="slate" className="mt-4">{t("main_page.data_collection")}</Badge>}</div>
                    </Card>;
                })}
            </>
            <Modal shown={modalShown} close={handleModalClose} widthClass="sm:max-w-6xl">
                <div className="text-center text-lg font-bold mb-8">{t(modalMode === "edit" ? "main_page.add_channels" : "main_page.delete_topic")} {topic?.title}</div>
                {
                        topic ? (modalMode === "edit" ? <EditTopic topic={topic!} /> : <DeleteTopic topic={topic!} close={handleModalClose} />) : <></>
                }
            </Modal>
        </WideCard>
    );
}

export default TopicsList;