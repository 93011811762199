import React, {useMemo, useState} from 'react';
import {Channel} from "../../../models/Channel";
import Card from "../../../ui/Card";
import {BadgeDelta, Callout, Icon} from "@tremor/react";
import {formatNumber, median} from "../../../utils/numbers";
import CategoryBar, {MarkerColor} from "../../../components/CategoryBar";
import Modal from "../../../ui/Modal";
import {useTranslation} from "react-i18next";
import {
    ArrowLongRightIcon,
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    ClockIcon,
    QuestionMarkCircleIcon
} from "@heroicons/react/20/solid";
import ChannelsTable from "../../../components/ChannelsTable";
import Status from "../../../ui/Status";
import {secondsToTimeString} from "../../../utils/date";

type Field = "avg_views" | "sum_views" | "median_vs" | "median_erv" | "median_score" | "current_videos" | "subscriptions" | "median_length" | "avg_views_shorts" | "sum_views_shorts" | "median_vs_shorts" | "median_erv_shorts" | "median_score_shorts" | "current_videos_shorts" | "median_length_shorts"

type ChannelRatingCardProps = {
    rating: Channel[] | null
    channel: Channel
    field: Field
    shorts: boolean
}

function ChannelRatingCard({rating, channel, field, shorts}: ChannelRatingCardProps) {
    const {t} = useTranslation();

    const [tooltipShown, setTooltipShown] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    const currentValue = useMemo(() => {
        return channel[field];
    }, [channel]);

    const values = useMemo(() => {
        if (!rating) {
            return [];
        }

        return rating.map((el) => el[field]);
    }, [rating]);

    const sortedValues = useMemo(() => {
        if (field === "median_score") {
            return values.map((el) => Math.ceil(el)).sort((a, b) => a - b).filter(Number);
        }
        return values.sort((a, b) => a - b).filter(Number);
    }, [values, field]);

    const currentValueIndex = useMemo(() => {
        if (field === "median_score") {
            return sortedValues.findIndex((el) => el === Math.ceil(currentValue));
        }
        return sortedValues.findIndex((el) => el === currentValue);
    }, [currentValue, sortedValues, field]);

    const valuesMedian = useMemo(() => {
        return median(sortedValues);
    }, [sortedValues]);

    const markerPosition = useMemo(() => {
        return currentValueIndex / ((sortedValues.length - 1) / 100);
    }, [sortedValues, currentValue]);

    const stripeValues = useMemo(() => {
        if (!valuesMedian || !sortedValues || !sortedValues.length) {
            return [33.33, 33.33, 33.33];
        }

        let red = [];
        let yellow = [];
        let green = [];

        for (let el of sortedValues) {
            if (el < (valuesMedian - (valuesMedian / 100 * 5))) {
                red.push(el);
            } else if (el > (valuesMedian + (valuesMedian / 100 * 5))) {
                green.push(el);
            } else {
                yellow.push(el);
            }
        }

        return [red.length, yellow.length, green.length];
    }, [valuesMedian, sortedValues])

    const stripeWidths = useMemo(() => {
        return [
            (stripeValues[0] / sortedValues.length) * 100,
            (stripeValues[1] / sortedValues.length) * 100,
            (stripeValues[2] / sortedValues.length) * 100
        ];
    }, [stripeValues, sortedValues]);

    const markerColor = useMemo<MarkerColor>(() => {
        if (markerPosition == 0 && stripeWidths[0] == 0) return "yellow";
        // + 0.00001 to fix when stripeWidths are 33.33333 and 66.66666
        if (markerPosition < stripeWidths[0]) {
            return "rose";
        } else if (markerPosition > (stripeWidths[0] + stripeWidths[1] + 0.00001)) {
            return "emerald";
        }
        return "yellow";
    }, [stripeWidths, markerPosition]);

    const relativeToMedian = useMemo(() => {
        if (!valuesMedian) return 0;
        return currentValue / valuesMedian * 100 - 100;
    }, [valuesMedian, currentValue]);

    const badgeColor = useMemo(() => {
        if (markerColor === "emerald") {
            return "moderateIncrease";
        } else if (markerColor === "rose") {
            return "moderateDecrease";
        }

        return "unchanged";
    }, [markerColor]);

    const calloutTitleText = useMemo(() => {
        if (markerColor === "emerald") {
            return "video_page.rating.great";
        } else if (markerColor === "rose") {
            return "video_page.rating.awful";
        }

        return "video_page.rating.ok";
    }, [markerColor]);

    const calloutText = useMemo(() => {
        if (markerColor === "emerald") {
            return "channel_page.rating.great." + field.replace("_shorts", "");
        } else if (markerColor === "rose") {
            return "channel_page.rating.awful." + field.replace("_shorts", "");
        }

        return "channel_page.rating.ok." + field.replace("_shorts", "");
    }, [markerColor, field])

    const calloutIcon = useMemo(() => {
        if (markerColor === "emerald") {
            return ArrowTrendingUpIcon;
        } else if (markerColor === "rose") {
            return ArrowTrendingDownIcon;
        }

        return ArrowLongRightIcon;
    }, [markerColor]);

    if (!rating) {
        return (
            <Card className="loadable loadable_active flex flex-col">
                <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                    headline={t("channel_page.no_rating")}
                    text={t("channel_page.no_rating_text")}
                />
            </Card>
        );
    }

    return (
        <Card className="overflow-visible flex flex-col">
            <div className="text-slate-700 text-2xl font-bold text-center">
                {sortedValues.length - currentValueIndex} {t("channel_page.place_in_this_topic")}
            </div>
            <div className="text-blue-500 text-sm text-center mb-8 mt-4 cursor-pointer" onClick={() => {setModalShown(true)}}>
                {t("video_page.show_rating")}
            </div>
            <div className="flex justify-between mb-2">
                <div className="text-slate-500 flex items-center">
                    {t("channel_page.rating." + field.replace("_shorts", ""))}
                    <div className="relative">
                        <Icon
                            icon={QuestionMarkCircleIcon}
                            size="sm"
                            color="slate"
                            className="cursor-pointer"
                            onMouseLeave={() => {setTooltipShown(false)}}
                            onMouseOver={() => {setTooltipShown(true)}}
                        />
                        <div
                            className={"absolute drop-shadow-md border border-slate-200 text-center transition-all px-4 w-96 py-1 text-sm rounded-md bg-slate-100 -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                            style={{left: "50%", bottom: "100%"}}
                        >
                            {t("channel_page.rating.tooltips." + field.replace("_shorts", ""))}
                        </div>
                    </div>
                </div>
                {/*{(!!channel[field]) && <BadgeDelta size="xl" deltaType={badgeColor}>{relativeToMedian > 0 ? "+" : ""}{relativeToMedian.toFixed(0)}%</BadgeDelta>}*/}
            </div>
            <div className={(!channel[field]) ? "flex grow flex-col justify-center" : ""}>
                {(!channel[field]) && <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                    headline={t("channel_page.no_rating")}
                    text={t("channel_page.no_rating_text")}
                />}
                {(!!channel[field]) && <>
                    <div className="text-4xl font-extrabold">{field === "median_length" ? secondsToTimeString(channel[field]) : formatNumber((field === "avg_views" || field === "sum_views" || field === "median_score" || field === "current_videos") ? Math.ceil(channel[field]) : channel[field], field == "median_vs" || field == "median_erv")}</div>
                    <Callout
                        className="mt-4 mb-16"
                        title={t(calloutTitleText, {percent: Math.abs(relativeToMedian).toFixed(0)})}
                        icon={calloutIcon}
                        color={markerColor}
                    >
                        {t("channel_page.rating.for_last_30_days")} {t(calloutText, {percent: Math.abs(relativeToMedian).toFixed(0)})}
                    </Callout>

                    <CategoryBar
                        sortedValues={sortedValues}
                        markerPosition={markerPosition}
                        markerColor={markerColor}
                        stripeWidths={stripeWidths}
                        stripeValues={stripeValues}
                        tooltipText="category_bar.channels"
                        currentValue={(field === "avg_views" || field === "sum_views" || field === "median_score" || field === "current_videos") ? Math.ceil(currentValue) : currentValue}
                        medianValue={(field === "avg_views" || field === "sum_views" || field === "median_score" || field === "current_videos") ? Math.ceil(valuesMedian!) : valuesMedian!}
                    />

                    <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                        <div className="text-center text-lg mb-8 font-bold">
                            {t("channel_page.modal.heading_" + field.replace("_shorts", ""))}
                        </div>
                        <ChannelsTable channels={rating} initialSortingKey={field} shorts={shorts} highlightId={channel.id} />
                    </Modal>
                </>}
            </div>
        </Card>
    );
}

export default ChannelRatingCard;